<template>
    <div>
        <el-card shadow="never" class="card">
            <div class="heasd"><i class="el-icon-arrow-left" @click="back" style="margin: 0 8px"></i>文章详情</div>
            <h1>{{tableData.title}}</h1>
            <div class="datasource">
                <span>发布日期：{{tableData.createTime}}</span>
                <span>来源：江西省人民政府</span>
            </div>
            <el-divider/>
            <div class="pages_content" v-html="tableData.content"></div>
        </el-card>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                createLoading: false,
                tableData: {
                    title: '',
                    createTime: '',
                    content: '',
                },
            }
        },

        methods: {
            getData() {
                this.$axios.get('/article', {
                    params: {
                        id: this.$route.params.id
                    }
                }).then(response => {
                    this.tableData.title = response.data.data.title;
                    this.tableData.createTime = response.data.data.clobDO.createTime;
                    this.tableData.content = response.data.data.clobDO.content;
                }).catch(error => {
                  console.error(error)
                })
            },
            back() {
                this.$router.back()
            },
        },

        created() {
            this.getData();
        },

    }
</script>

<style scoped>
.heasd {
    border-bottom: 1px #ddd solid;
    text-align: left;
    line-height: 40px;
    padding: 0 0 0 8px;
    color: #2380d7;
    font-weight: bold;
}
    .card {
        width: 1200px;
        margin: auto;
    }
    h1 {
        padding: 16px 0 0 0;
    }
    p {
        padding: 8px 24px;
        text-align: left;
        text-indent: 2em;
        line-height: 32px;
    }
    .datasource span{ padding: 0 10px}
    .pages_content {
        padding: 20px;
        text-align: left;
        line-height: 36px;
        text-indent: 2em;
        word-wrap: break-word;
        word-break: normal;
        min-height:500px
    }
</style>